<template>
  <b-modal id="loan-detail-modal" size="lg" title="Detalle">
    <div class="mb-6 flex space-x-36" v-if="row">
      <div class="flex flex-col space-y-2">
        <div>
          <strong>Fecha de creación</strong>:<br />{{
            formatDate(row.created_at)
          }}
        </div>
        <div>
          <strong>Fecha límite</strong>:<br />{{ formatDate(row.finish_date) }}
        </div>
      </div>
      <div class="flex flex-col space-y-2">
        <div>
          <strong class="block">Capital:</strong>
          ${{ row.capital | numericFormat }}
        </div>
        <div>
          <strong class="block">Porc. interés:</strong>
          {{ row.interest_perc }}%
        </div>
      </div>
      <div class="flex flex-col">
        <strong class="block">Estado:</strong>
        <b-badge :variant="statusClass">
          <span class="text-lg">
            {{ statusText }}
          </span>
        </b-badge>
      </div>
    </div>
    <h3 class="page-title">
      Abonos / Pagos
    </h3>
    <v-client-table
      :data="records"
      :columns="columns"
      :options="options"
      ref="table"
    >
      <tr slot="appendBody">
        <td>
          <strong>Totales:</strong>
        </td>
        <td>${{ totalCapital | numericFormat }}</td>
        <td>${{ totalInterestValue | numericFormat }}</td>
        <td></td>
      </tr>
    </v-client-table>

    <template #modal-footer>
      <b-button size="sm" variant="outline-inverse" @click="closeModal">
        Cerrar
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import { formatDate } from "@/utils/utils";
export default {
  props: ["row"],

  computed: {
    columns() {
      let cols = this.options.columns.map(col => {
        return col.prop;
      });

      return cols;
    },

    headings() {
      let heads = {};

      this.options.columns.forEach(col => {
        heads[col.prop] = col.header;
      });

      heads.actions = "";
      return heads;
    },

    records() {
      if (this.row) {
        return this.row.payments.filter(item => item.current_capital === 0);
      }

      return [];
    },

    statusText() {
      let statusText = "";
      if (this.row) {
        statusText = this.row.is_active ? "Activo" : "Finalizado";
      }

      return statusText;
    },

    statusClass() {
      let statusClass = "";
      if (this.row) {
        statusClass = this.row.is_active ? "success" : "inverse";
      }

      return statusClass;
    },

    totalCapital() {
      const me = this;
      return me.records.reduce((sum, rec) => sum + rec.capital_payment, 0);
    },

    totalInterestValue() {
      const me = this;

      return me.records.reduce((sum, rec) => sum + rec.interest_payment, 0);
    }
  },

  data() {
    return {
      options: {
        columns: [
          {
            header: "Fecha",
            prop: "created_at"
          },
          {
            header: "Capital",
            prop: "capital_payment"
          },
          {
            header: "Intereses",
            prop: "interest_payment"
          },
          {
            header: "Descripción",
            prop: "description"
          }
        ],
        sortable: [],
        filterable: false
      }
    };
  },

  methods: {
    formatDate(date) {
      return formatDate(date);
    },

    closeModal() {
      this.$bvModal.hide("loan-detail-modal");
    }
  },

  mounted() {
    const me = this;
    me.options = {
      ...me.options,
      ...{
        headings: me.headings,
        templates: {
          capital_payment(h, row) {
            return `$${me.$options.filters.numericFormat(row.capital_payment)}`;
          },

          interest_payment(h, row) {
            return `$${me.$options.filters.numericFormat(
              row.interest_payment
            )}`;
          },

          created_at(h, row) {
            return formatDate(row.created_at);
          }
        }
      }
    };
  }
};
</script>

<style lang="scss" scoped></style>
