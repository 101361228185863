<template>
  <b-modal id="payments-modal" size="lg" title="Abonar / Pagar">
    <div v-if="row">
      <h5 class="mb-6">
        <strong>El abono máximo a Capital es de:</strong>
        ${{ maxCapitalValue | numericFormat }}
      </h5>
      <h5 class="mb-6">
        <strong>El abono máximo a Interes es de:</strong>
        ${{ maxInterestValue | numericFormat }}
      </h5>
      <b-form @submit.stop.prevent="submit" ref="form">
        <input type="hidden" name="interest_payment" v-model="paymentTemp" />
        <div class="flex space-x-4">
          <b-form-group label="Abonar" class="flex-1 required">
            <b-form-input
              v-currency="optionsCurrency"
              @input="calculatePayment"
              v-model="paymentTemp"
            ></b-form-input>
          </b-form-group>
          <b-form-group label="Interés" class="flex-1 required">
            <b-form-input
              name="interest_payment"
              type="number"
              disabled
              :max="maxInterestValue"
              v-model.trim="formFields.interest_payment"
            >
            </b-form-input>
          </b-form-group>
          <b-form-group label="Capital" class="flex-1 required">
            <b-form-input
              name="capital_payment"
              type="number"
              disabled
              v-model.trim="formFields.capital_payment"
            >
            </b-form-input>
          </b-form-group>
        </div>
        <div>
          <b-form-group label="Descripción" class="flex-1">
            <b-form-textarea
              name="description"
              v-model="formFields.description"
            ></b-form-textarea>
          </b-form-group>
        </div>
      </b-form>
    </div>
    <template #modal-footer>
      <b-button size="sm" variant="outline-inverse" @click="closeModal">
        Cancelar
      </b-button>
      <b-button size="sm" variant="success" @click="submit">
        Guardar
      </b-button>
    </template>
    <b-overlay
      :show="isLoading"
      opacity="0.6"
      spinner-small
      spinner-variant="primary"
      rounded="sm"
      no-wrap
    >
    </b-overlay>
  </b-modal>
</template>

<script>
import { v4 as uuidv4 } from "uuid";
import { CurrencyDirective } from "vue-currency-input";
export default {
  props: ["row"],

  inject: ["loanPaymentsRepository"],

  computed: {
    maxCapitalValue() {
      const me = this;
      return me.row.capital;
    },
    maxInterestValue() {
      const me = this;
      return (Number(me.row.capital) * Number(me.row.interest_perc)) / 100;
    }
  },

  directives: {
    currency: CurrencyDirective
  },

  data() {
    return {
      isLoading: false,
      paymentTemp: 0,
      formFields: {
        capital_payment: 0,
        interest_payment: 0,
        description: ""
      },

      optionsCurrency: {
        locale: "es",
        currency: {
          prefix: "$"
        }
      }
    };
  },

  methods: {
    closeModal() {
      this.$bvModal.hide("payments-modal");
    },

    submit() {
      const me = this;
      if (me.formFields.interest_payment > 0) {
        let data = {
          id: uuidv4(),
          current_capital: 0,
          interest_payment: parseFloat(me.formFields.interest_payment),
          capital_payment: parseFloat(me.formFields.capital_payment),
          description: me.formFields.description,
          loan_id: me.row.id
        };

        me.isLoading = true;

        me.loanPaymentsRepository
          .save(data)
          .then(response => {
            me.isLoading = false;
            me.paymentTemp = 0;
            me.formFields.interest_payment = 0;
            me.formFields.capital_payment = 0;
            me.$parent.handleLoadData(me.$parent.selectedClient);
            me.closeModal();
          })
          .catch(error => {
            me.isLoading = false;
            console.log(error);
          });
      }
    },

    calculatePayment(value) {
      const me = this;

      if (value <= me.maxInterestValue) {
        me.formFields.interest_payment = value;
        me.formFields.capital_payment = 0;
      }

      if (value === "") {
        me.formFields.interest_payment = 0;
        me.formFields.capital_payment = 0;
      }

      if (value > me.maxInterestValue) {
        let moneyToCapital = value - me.maxInterestValue;

        if (moneyToCapital >= me.maxCapitalValue) {
          me.formFields.capital_payment = me.maxCapitalValue;
        } else {
          me.formFields.capital_payment = value - me.maxInterestValue;
        }
        me.formFields.interest_payment = me.maxInterestValue;
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
