<template>
  <b-modal id="loan-form-modal" size="lg" title="Prestar">
    <div>
      <b-form @submit.stop.prevent="submit" ref="form">
        <input type="hidden" name="capital" v-model="formFields.capital" />
        <div class="flex space-x-4">
          <b-form-group label="Capital" class="flex-1 required">
            <b-form-input
              v-currency="optionsCurrency"
              v-model.trim="formFields.capital"
            >
            </b-form-input>
          </b-form-group>
          <b-form-group label="Porcentaje interés" class="flex-1 required">
            <b-form-input
              name="interest_perc"
              type="number"
              v-model.trim="formFields.interest_perc"
            >
            </b-form-input>
          </b-form-group>
          <b-form-group label="Fecha límite" class="flex-1 required">
            <b-form-datepicker
              name="finish_date"
              locale="es-ES"
              :date-format-options="{
                year: 'numeric',
                month: 'numeric',
                day: 'numeric'
              }"
              v-model.trim="formFields.finish_date"
            >
            </b-form-datepicker>
          </b-form-group>
        </div>
        <b-alert v-model="isAbleToLean" variant="danger" v-if="client">
          <span class="font-bold text-base">
            No es posible realizar préstamo porque el monto máximo a prestar es
            de ${{ client.maximum_loan | numericFormat }}
          </span>
        </b-alert>
        <div class="mt-4">
          <h3>Resúmen préstamo</h3>
          <div class="flex justify-between">
            <div>
              <span class="font-semibold block">Capital:</span>
              <div>${{ formFields.capital | numericFormat }}</div>
            </div>
            <div>
              <span class="font-semibold block">Porcentaje interés:</span>
              <div>{{ formFields.interest_perc }}%</div>
            </div>
            <div>
              <span class="font-semibold block">Valor cuota mensual:</span>
              <div>
                ${{
                  interestValue(formFields.capital, formFields.interest_perc)
                    | numericFormat
                }}
              </div>
            </div>
            <div>
              <span class="font-semibold block">Total préstamo:</span>
              <div>${{ parseCreditTotal() | numericFormat }}</div>
            </div>
          </div>
          <div class="mt-4">
            <div v-if="formFields.finish_date" class="flex space-x-10">
              <div>
                <span class="font-semibold block">Duración:</span>
                {{ formFields.duration.days }} días
              </div>
              <div>
                <span class="font-semibold block">Fecha finalización:</span>
                {{ parseDate(formFields.finish_date) }}
              </div>
            </div>
          </div>
        </div>
      </b-form>
    </div>
    <template #modal-footer>
      <b-button size="sm" variant="outline-inverse" @click="closeModal">
        Cancelar
      </b-button>
      <b-button size="sm" variant="success" @click="submit">
        Guardar
      </b-button>
    </template>
    <b-overlay
      :show="isLoading"
      opacity="0.6"
      spinner-small
      spinner-variant="primary"
      rounded="sm"
      no-wrap
    >
    </b-overlay>
  </b-modal>
</template>

<script>
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import { getDays, getInterestValue } from "@/utils/utils";
import { CurrencyDirective } from "vue-currency-input";

moment.locale("es");

export default {
  props: ["client"],

  inject: ["loansRepository"],

  computed: {
    isAbleToLean() {
      const me = this;

      return me.parseCreditTotal() > me.client.maximum_loan;
    }
  },

  directives: {
    currency: CurrencyDirective
  },

  data() {
    return {
      isLoading: false,
      formFields: {
        capital: 0,
        interest_perc: 0,
        is_active: true,
        finish_date: null,
        duration: {
          days: 0
        }
      },

      optionsCurrency: {
        locale: "es",
        currency: {
          prefix: "$"
        }
      }
    };
  },

  methods: {
    closeModal() {
      this.$bvModal.hide("loan-form-modal");
    },

    submit() {
      const me = this;

      if (
        me.formFields.capital > 0 &&
        me.formFields.interest_perc > 0 &&
        me.formFields.finish_date &&
        !me.isAbleToLean
      ) {
        me.isLoading = true;

        let data = {
          id: uuidv4(),
          client_id: me.client.id,
          capital: Number(me.formFields.capital),
          interest_perc: Number(me.formFields.interest_perc),
          finish_date: me.formFields.finish_date,
          is_active: 1
        };

        me.loansRepository
          .save(data)
          .then(() => {
            me.isLoading = false;
            me.formFields.capital = 0;
            me.formFields.interest_perc = 0;
            me.formFields.finish_date = null;
            me.$parent.handleLoadData(me.client.id);
            me.closeModal();
          })
          .catch(error => {
            me.isLoading = false;
            console.log(error);
          });
      }
    },

    parseDate(date) {
      return moment(date)
        .utc()
        .format("ddd, MMMM Do YYYY");
    },

    interestValue(capital, interest_perc) {
      return (Number(capital) * Number(interest_perc)) / 100;
    },

    parseCreditTotal() {
      const me = this;
      const now = moment().utc();
      let dues = getDays(now, me.formFields.finish_date);

      me.formFields.duration.days = dues;

      //Interest calculated by passed days
      let interestValue = getInterestValue(
        me.interestValue(me.formFields.capital, me.formFields.interest_perc),
        dues
      );

      return !isNaN(interestValue + me.formFields.capital)
        ? interestValue + parseInt(me.formFields.capital)
        : 0;
    }
  }
};
</script>

<style lang="scss" scoped></style>
