<template>
  <div>
    <Widget class="shadow-xl">
      <h1 class="page-title">
        Préstamos
      </h1>
      <div class="flex justify-between mb-6">
        <div class="flex items-center space-x-2">
          <i class="fa fa-search text-xl"></i>
          <v-select
            :options="clients"
            :reduce="client => client.id"
            label="searchField"
            placeholder="Buscar cliente(cédula, nombre, apellido)"
            class="w-96"
            v-model="selectedClient"
            @input="handleSelect"
          />
          <b-button
            size="sm"
            variant="outline-primary"
            v-b-modal.modal-client-form
          >
            <i class="glyphicon glyphicon-plus transform -translate-y-1"></i>
          </b-button>
        </div>
        <b-button
          variant="success"
          :disabled="disableAllowToLoan"
          v-b-modal.loan-form-modal
          @click="selectedClientToCreateCredit = selectedClient"
        >
          <i class="fa fa-money-bill-wave"></i>
          Prestar
        </b-button>
      </div>
      <div class="flex flex-col items-center mb-6" v-if="selectedClient">
        <h3>
          <span class="relative inline-flex">
            <span class="mr-3">{{ currentClient.full_name }}</span>
            <span
              class="flex absolute h-4 w-4 top-3 right-0 -mt-1 -mr-1"
              v-if="expiredCredits.length > 0"
            >
              <span
                class="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-400 opacity-75"
              ></span>
              <span
                class="relative inline-flex rounded-full h-4 w-4 bg-red-600"
              ></span>
            </span>
          </span>
        </h3>
        <div class="flex space-x-4">
          <div>
            <strong>C.C.</strong>
            {{ currentClient.identity_number | numericFormat }}
          </div>
          <div>
            <strong>Salario</strong> ${{ currentClient.salary | numericFormat }}
          </div>
          <div>
            <strong>Monto max. a prestar</strong> ${{
              currentClient.maximum_loan | numericFormat
            }}
          </div>
        </div>
      </div>
      <v-client-table
        :data="records"
        :columns="columns"
        :options="localOptions"
        ref="table"
      >
        <div
          slot="actions"
          slot-scope="{ row }"
          class="flex items-center space-x-2"
        >
          <b-button
            size="sm"
            variant="outline-primary"
            @click="selectedRow = row"
            v-b-modal.payments-modal
            title="Abonar/Pagar"
            v-if="row.is_active"
          >
            <i class="fa fa-money-bill-wave"></i>
          </b-button>
          <b-button
            size="sm"
            variant="outline-primary"
            @click="selectedRow = row"
            v-b-modal.loan-detail-modal
            title="Detalles"
          >
            <i
              class="glyphicon glyphicon-eye-open transform -translate-y-1"
            ></i>
          </b-button>
          <b-button
            size="sm"
            variant="outline-primary"
            @click="selectedRow = row"
            v-b-modal.modal-documents-form
            title="Documentos"
          >
            <i
              class="glyphicon glyphicon-folder-open transform -translate-y-1"
            ></i>
          </b-button>
          <b-button
            size="sm"
            variant="outline-primary"
            @click="selectedRow = row"
            v-b-modal.modal-process-lawyer-form
            v-if="handleExpired(row)"
            title="Abrir proceso"
          >
            <i class="fa fa-balance-scale"></i>
          </b-button>
        </div>
        <tr slot="appendBody">
          <td>
            <strong>Totales:</strong>
          </td>
          <td>${{ totalCapital | numericFormat }}</td>
          <td></td>
          <td></td>
          <td>${{ totalInterestValue | numericFormat }}</td>
          <td>-</td>
          <td colspan="4"></td>
        </tr>
      </v-client-table>
    </Widget>
    <PaymentsModal :row="selectedRow"></PaymentsModal>
    <LoanDetailsModal :row="selectedRow"></LoanDetailsModal>
    <LoanFormModal :client="currentClient"></LoanFormModal>
    <base-modal-documents-form title="Documentos" :row="selectedRow">
      <template v-slot:formfields>
        <b-form-group label="Nombre" class="flex-1">
          <b-form-input name="name"> </b-form-input>
        </b-form-group>
        <b-form-group label="Archivo" class="flex-1">
          <b-form-file
            name="file"
            ref="file"
            accept=".jpg, .png, .gif, .pdf"
          ></b-form-file>
        </b-form-group>
      </template>
    </base-modal-documents-form>
    <client-form
      :handlePostSubmit="handlePostSubmitClient"
      id="modal-client-form"
    />

    <process-lawyer-form
      :handlePostSubmit="handlePostSubmitProcess"
      :idModal="idModal"
      :disabled="disabled"
      :record="selectedRow"
    />
  </div>
</template>

<script>
import { customComponentActions } from "@/mixins/actions.js";
import { deserialize } from "jsonapi-fractal";
import moment from "moment";
import { formatDate, getDays, getInterestValue } from "@/utils/utils";
import PaymentsModal from "./PaymentsModal";
import LoanDetailsModal from "./LoanDetailsModal";
import LoanFormModal from "./LoanFormModal";
import ProcessLawyerForm from "../Processlawyer/ProcessLawyerForm";
import BaseModalDocumentsForm from "@/components/Base/BaseModalDocumentsForm";
import ClientForm from "@/admin/Clients/ClientForm";

export default {
  inject: ["loansRepository", "clientsRepository"],

  mixins: [customComponentActions],

  computed: {
    columns() {
      let cols = this.localOptions.columns.map(col => {
        return col.prop;
      });

      cols.push("actions");
      return cols;
    },

    headings() {
      let heads = {};

      this.localOptions.columns.forEach(col => {
        heads[col.prop] = col.header;
      });

      heads.actions = "";
      return heads;
    },

    disableAllowToLoan() {
      const me = this;

      if (!me.selectedClient) {
        return true;
      }

      return false;
    },

    currentClient() {
      const me = this;

      return me.clients.find(item => item.id === me.selectedClient);
    },

    totalCapital() {
      const me = this;
      return me.records.reduce((sum, rec) => sum + rec.capital, 0);
    },

    totalInterestValue() {
      const me = this;

      return me.records.reduce(
        (sum, rec) =>
          sum + (Number(rec.capital) * Number(rec.interest_perc)) / 100,
        0
      );
    },

    totalCapitalInterestValue() {
      const me = this;

      return me.records.reduce(
        (sum, rec) =>
          sum + (Number(rec.capital) * Number(rec.interest_perc)) / 100,
        0
      );
    },

    expiredCredits() {
      const me = this;
      let expiredRecords = [];

      me.records.forEach(rec => {
        if (me.handleExpired(rec)) {
          expiredRecords.push(rec);
        }
      });

      return expiredRecords;
    }
  },

  components: {
    PaymentsModal,
    LoanDetailsModal,
    LoanFormModal,
    BaseModalDocumentsForm,
    ClientForm,
    ProcessLawyerForm
  },

  data() {
    return {
      records: [],
      localOptions: {
        columns: [
          {
            header: "Fecha",
            prop: "created_at"
          },
          {
            header: "Capital",
            prop: "capital"
          },
          {
            header: "Días",
            prop: "days"
          },
          {
            header: "%Interés",
            prop: "interest_perc"
          },
          {
            header: "Valor Interés",
            prop: "interest_value"
          },
          {
            header: "Capital + Interés",
            prop: "capital_interest"
          },
          {
            header: "Fecha Últ. abono",
            prop: "last_payment"
          },
          {
            header: "Fecha límite",
            prop: "finish_date"
          },
          {
            header: "Estado",
            prop: "is_active"
          }
        ],
        sortable: [],
        filterable: false,
        entity: "loans",
        primary: "id",
        pagination: {
          show: false
        },
        perPage: 1000,
        repository: {
          name: "loansRepository"
        }
      },

      formFields: {
        id: null
      },

      selectedRow: null,

      selectedClient: null,
      selectedClientToCreateCredit: null,

      clients: [],
      idModal: "modal-process-lawyer-form",
      disabled: true
    };
  },

  methods: {
    handleLoadData(id) {
      const me = this;

      me.loansRepository
        .get(id)
        .then(response => {
          let result = deserialize(response.data);
          me.records = result.map(item => {
            item["payments"] = item.loanpayments.sort((a, b) => {
              return (
                moment(b.created_at).format("YYYYMMDD") -
                moment(a.created_at).format("YYYYMMDD")
              );
            });

            return item;
          });
        })
        .catch(error => console.log(error));
    },

    handleSelect(val) {
      const me = this;

      if (val) {
        me.handleLoadData(val);
      } else {
        me.records = [];
      }
    },

    interestValue(capital, interest_perc) {
      return (Number(capital) * Number(interest_perc)) / 100;
    },

    handleExpired(row) {
      return (
        moment()
          .utc()
          .diff(moment(row.finish_date).utc(), "days") > 0 && row.is_active
      );
    },

    handleLoadClients() {
      const me = this;
      me.clientsRepository
        .get()
        .then(response => {
          me.clients = deserialize(response.data).map(item => ({
            ...item,
            searchField: `${item.identity_number} - ${item.full_name}`
          }));

          if (
            Object.prototype.hasOwnProperty.call(me.$route.params, "clientId")
          ) {
            me.selectedClient = me.$route.params.clientId;
            me.handleLoadData(me.selectedClient);
          }
        })
        .catch();
    },

    handlePostSubmitClient() {
      this.handleLoadClients();
    },

    handlePostSubmitProcess() {
      this.$router.push({
        path: "processlawyer"
      });
    }
  },

  mounted() {
    const me = this;
    me.localOptions = {
      ...me.localOptions,
      ...{
        headings: me.headings,
        templates: {
          capital(h, row) {
            return `$${me.$options.filters.numericFormat(row.capital)}`;
          },

          interest_value(h, row) {
            let interestValue = me.interestValue(
              row.capital,
              row.interest_perc
            );

            return `$${me.$options.filters.numericFormat(interestValue)}`;
          },

          days(h, row) {
            const now = moment();
            const finish_date = moment(row.payments[0].created_at)
              .utc()
              .diff(moment(row.finish_date).utc(), "days");

            if (finish_date > 0) {
              return getDays(row.payments[0].created_at, now);
            }

            return getDays(row.payments[0].created_at, row.finish_date);
          },

          capital_interest(h, row) {
            const now = moment().utc();
            let dues = 0;

            const finish_date = moment(row.payments[0].created_at)
              .utc()
              .diff(moment(row.finish_date).utc(), "days");

            if (finish_date > 0) {
              dues = getDays(row.payments[0].created_at, now);
            } else {
              dues = getDays(row.payments[0].created_at, row.finish_date);
            }

            //Interest calculated by passed days
            let interestValue = getInterestValue(
              me.interestValue(row.capital, row.interest_perc),
              dues
            );

            //Capital interest
            let capitalInterestValue = interestValue + row.capital;

            return `$${me.$options.filters.numericFormat(
              capitalInterestValue
            )}`;
          },

          created_at(h, row) {
            return formatDate(row.created_at);
          },

          last_payment(h, row) {
            return formatDate(row.payments[0].created_at);
          },

          finish_date(h, row) {
            return formatDate(row.finish_date);
          },

          is_active(h, row) {
            let variant = row.is_active ? "success" : "inverse";
            let textStatus = row.is_active ? "Activo" : "Finalizado";

            if (row.capital === 0) {
              variant = "inverse";
              textStatus = "Finalizado";
              row.is_active = false;
            }

            if (me.handleExpired(row)) {
              variant = "danger";
            }

            return h(
              "b-badge",
              {
                props: {
                  variant
                }
              },
              textStatus
            );
          }
        }
      }
    };

    me.handleLoadClients();
  }
};
</script>

<style lang="scss" scoped></style>
